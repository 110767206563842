@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family:'Avenir LT W01_45 Book1475508', sans-serif;
}

h1,h2,h3{
  font-family:'Avenir LT W01_45 Book1475508', sans-serif;
}

h1{
  font-size: 83px;
}

strong{
  font-family:'Avenir LT W01_85 Heavy1475544', sans-serif;
}